<template>
    <div>
        <div class="documentLabelContent" id="ygh-content-box">
            <a-row
                :gutter="20"
                type="flex"
                justify="space-between"
                style="margin-left: 0; margin-right: 0"
                class="height100 documentLabelContent1"
            >
                <a-col :md="6" :sm="24" style="padding-left: 0">
                    <a-card class="height100">
                        <div class="tagSearch">
                            <a-form layout="inline">
                                <a-row :gutter="10" type="flex" align="middle">
                                    <a-col :md="16" :sm="24">
                                        <a-form-item>
                                            <a-input
                                                @keyup.enter.native="getTags"
                                                v-model="queryParam.name"
                                                allow-clear
                                                placeholder="请输入标签名称"
                                            />
                                        </a-form-item>
                                    </a-col>
                                    <a-col :md="8" :sm="24">
                                        <span class="table-page-search-submitButtons">
                                            <a-button
                                                @click="$refs.addForm.add()"
                                                type="dashed"
                                                shape="circle"
                                                icon="plus"
                                            ></a-button>
                                        </span>
                                    </a-col>
                                </a-row>
                            </a-form>
                        </div>
                        <div class="tagContainer">
                            <div class="tags">
                                <ul>
                                    <li
                                        class="linContainer"
                                        :class="{
                                            active: tagActive === tagIndex,
                                        }"
                                        @click="changeActive(tag, tagIndex)"
                                        v-for="(tag, tagIndex) in tags"
                                        :key="tag.id"
                                    >
                                        <div class="flex0">
                                            <div>
                                                <img class="tagIcon" src="/images/tag.svg" alt="" />
                                            </div>
                                            <div>
                                                {{ tag.name }}
                                            </div>
                                        </div>
                                        <div class="more" @click.stop="showTodo(tagIndex)">
                                            <a-icon type="more" />
                                            <ul class="todo" v-if="tagIndex === tagTodoActive">
                                                <li class="item" @click="$refs.editForm.edit(tag)">编辑</li>
                                                <li class="item del">
                                                    <a-popconfirm
                                                        v-if="hasPerm('documentLable:delete')"
                                                        placement="topRight"
                                                        title="确认删除？"
                                                        @confirm="() => singleDelete(tag)"
                                                    >
                                                        <div>删除</div>
                                                    </a-popconfirm>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </a-card>
                </a-col>
                <a-col :md="18" :sm="24" style="padding-right: 0">
                    <a-card class="height100">
                        <PersonList :tagActiveRow="tagActiveRow"></PersonList>
                    </a-card>
                </a-col>
            </a-row>
        </div>
        <add-form ref="addForm" @ok="handleOk" />
        <edit-form ref="editForm" @ok="handleOk" />
    </div>
</template>
<script>
import {
    documentLableDelete,
    documentLableExport,
    documentLablePage,
} from '@/api/modular/fileStream/documentLabelManage'
import { STable, XDown } from '@/components'
import addForm from './addForm.vue'
import editForm from './editForm.vue'
import PersonList from './table.vue'
export default {
    components: {
        STable,
        addForm,
        editForm,
        XDown,
        PersonList,
    },
    data() {
        return {
            // 查询参数
            queryParam: {},
            tags: [],
            tagActive: 0,
            tagActiveRow: {},
            tagTodoActive: -1,
            // 表头
            columns: [
                {
                    title: '标签名称',
                    align: 'center',
                    dataIndex: 'name',
                },
                {
                    title: '标签顺序',
                    align: 'center',
                    dataIndex: 'sort',
                },
            ],
            tstyle: { 'padding-bottom': '0px', 'margin-bottom': '10px' },
            // 加载数据方法 必须为 Promise 对象
            loadData: (parameter) => {
                return documentLablePage(Object.assign(parameter, this.queryParam)).then((res) => {
                    return res.data
                })
            },
            selectedRowKeys: [],
            selectedRows: [],
            options: {
                alert: {
                    show: true,
                    clear: () => {
                        this.selectedRowKeys = []
                    },
                },
                rowSelection: {
                    selectedRowKeys: this.selectedRowKeys,
                    onChange: this.onSelectChange,
                },
            },
        }
    },
    created() {
        if (this.hasPerm('documentLable:edit') || this.hasPerm('documentLable:delete')) {
            this.columns.push({
                title: '操作',
                width: '150px',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
            })
        }
        this.getTags()
        document.onclick = () => {
            this.tagTodoActive = -1
        }
    },
    beforeDestroy() {
        document.onclick = null
    },
    methods: {
        showTodo(tagIndex) {
            this.tagTodoActive = tagIndex
        },
        /**
         * 单个删除
         */
        singleDelete(record) {
            console.log(record)
            const param = [{ id: record.id }]
            this.documentLableDelete(param)
        },
        /**
         * 批量删除
         */
        batchDelete() {
            const paramIds = this.selectedRowKeys.map((d) => {
                return { id: d }
            })
            this.documentLableDelete(paramIds)
        },
        documentLableDelete(record) {
            documentLableDelete(record).then((res) => {
                if (res.success) {
                    this.$message.success('删除成功')
                    this.getTags()
                } else {
                    this.$message.error('删除失败') // + res.message
                }
            })
        },
        /**
         * 批量导出
         */
        batchExport() {
            const paramIds = this.selectedRowKeys.map((d) => {
                return { id: d }
            })
            documentLableExport(paramIds).then((res) => {
                this.$refs.batchExport.downloadfile(res)
            })
        },
        handleOk() {
            this.getTags()
        },
        changeActive(tag, tagIndex) {
            this.tagActive = tagIndex
            this.tagActiveRow = tag
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
        },
        getTags() {
            documentLablePage(this.queryParam).then((res) => {
                if (res.code === 200) {
                    this.tags = res.data.rows
                    this.tagActiveRow = res.data.rows.length > 0 ? res.data.rows[0] : {}
                } else {
                    this.$message.warning(res.message)
                }
            })
        },
    },
}
</script>
<style lang="less">
@import url('../../common');

.table-operator {
    margin-bottom: 18px;
}

button {
    margin-right: 8px;
}

.documentLabelContent {
    width: 1280px;
    margin: 0 auto;
    .documentLabelContent1 {
        min-height: 850px;
    }
}
</style>
